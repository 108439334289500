import React from 'react';
import classnames from 'classnames';
import * as styles from './NewsItemForm.module.css';
import { Icon } from './Icon';

const submitFeedback = (status) => {
  if (status === 'submitted') {
    return (
      <div className={classnames(styles.formControl, styles.formFeedback)}>
        Ihre Anmeldung wurde erfolgreich abgeschickt!
      </div>
    );
  }
  if (status === 'error') {
    return (
      <div className={classnames(styles.formControl, styles.formFeedback, styles.error)}>
        Es kam leider zu einem Fehler beim Absenden des Formulars!
      </div>
    );
  }
  return null;
};

function VarioweltAcademyItemForm(props) {
  const { values, touched, errors, status, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, handleReset } =
    props;

  console.log(errors);
  return (
    <div>
      <form onSubmit={handleSubmit} className={classnames(styles.NewsItemForm, styles[values.layout] || styles.small)}>
        <div className={styles.formControl}>
          <label>Name</label>
          <div className={styles.row}>
            <input
              id="firstName"
              placeholder="Vorname"
              aria-label="Vorname"
              aria-required="true"
              type="text"
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              className={errors.firstName && touched.firstName ? 'text-input error' : 'text-input'}
            />
            <input
              id="lastName"
              placeholder="Nachname"
              aria-label="Nachname"
              type="text"
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              className={errors.lastName && touched.lastName ? 'text-input error' : 'text-input'}
            />
          </div>
          <label>Anschrift</label>
          <div className={styles.column}>
            <input
              id="street"
              placeholder="Straße, Nr."
              aria-label="Straße, Nr."
              type="text"
              value={values.street}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <div className={styles.row}>
              <input
                id="postcode"
                placeholder="PLZ"
                aria-label="PLZ"
                type="text"
                value={values.postcode}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <input
                id="city"
                placeholder="Ort"
                aria-label="Ort"
                type="text"
                value={values.city}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </div>
          <label>Telefon</label>
          <input
            id="phone"
            placeholder="Telefon"
            aria-label="Telefon"
            type="tel"
            value={values.phone}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <label>E-Mail</label>
          <input
            id="email"
            placeholder="Ihre E-Mail Adresse"
            aria-label="Ihre E-Mail Adresse"
            aria-required="true"
            autoComplete="email"
            type="text"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            className={errors.email && touched.email ? 'text-input error' : 'text-input'}
          />
          <div className={styles.row}>
            <div className={styles.column}>
              <label>Schulung</label>
              <select id={'schulung'} value={values.schulung} onChange={handleChange} onBlur={handleBlur}>
                <optgroup label="Fahrerschulung">
                  <option>18.3.2025 - FendtONE Fahrer Schulung inkl. Grundkenntnisse</option>
                  <option>19.3.2025 - FendtONE Fahrer Schulung inkl. Grundkenntnisse</option>
                  <option>13.3.2025 - FendtONE Lenksystem Schulung für Profis</option>
                </optgroup>
              </select>
            </div>
            <div className={styles.column}>
              <label>Anzahl Personen</label>
              <input
                id="persons"
                placeholder="Anzahl Personen"
                aria-label="Anzahl Personen"
                type="number"
                value={values.persons}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </div>
          <fieldset className={styles.fieldset}>
            <legend>Traktor 1</legend>
            <div className={styles.row}>
              <div className={styles.column}>
                <label>Traktortyp</label>
                <input
                  id="traktor1_type"
                  placeholder="Traktortyp"
                  aria-label="Traktortyp"
                  value={values.traktor1_type}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div className={styles.column}>
                <label>Baujahr</label>
                <input
                  id="traktor1_year"
                  placeholder="Baujahr"
                  aria-label="Baujahr"
                  value={values.traktor1_year}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
            <label>Fahrgestellnummer</label>
            <input
              id="traktor1_vin"
              placeholder="Fahrgestellnr."
              aria-label="Fahrgestellnummer"
              aria-required="true"
              type="text"
              value={values.traktor1_vin}
              onChange={handleChange}
              onBlur={handleBlur}
              className={errors.traktor1_vin && touched.traktor1_vin ? 'text-input error' : 'text-input'}
            />
          </fieldset>
          <fieldset className={styles.fieldset}>
            <legend>Traktor 2</legend>
            <div className={styles.row}>
              <div className={styles.column}>
                <label>Traktortyp</label>
                <input
                  id="traktor2_type"
                  placeholder="Traktortyp"
                  aria-label="Traktortyp"
                  value={values.traktor2_type}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div className={styles.column}>
                <label>Baujahr</label>
                <input
                  id="traktor2_year"
                  placeholder="Baujahr"
                  aria-label="Baujahr"
                  type="text"
                  value={values.traktor2_year}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
            <label>Fahrgestellnummer</label>
            <input
              id="traktor2_vin"
              placeholder="Fahrgestellnummer"
              aria-label="Fahrgestellnummer"
              aria-required="true"
              type="text"
              value={values.traktor2_vin}
              onChange={handleChange}
              onBlur={handleBlur}
              className={errors.traktor2_vin && touched.traktor2_vin ? 'text-input error' : 'text-input'}
            />
          </fieldset>
          {values.layout === 'wide' && submitFeedback(status)}
        </div>
        <div className={styles.formControl}>
          <label>Anmerkungen</label>
          <textarea
            id={'message'}
            value={values.message}
            onChange={handleChange}
            onBlur={handleBlur}
            className={errors.message && touched.message ? 'text-input error' : 'text-input'}
          />
          <button type="submit" disabled={isSubmitting}>
            Jetzt anmelden
          </button>
        </div>
        {values.layout !== 'wide' && submitFeedback(status)}
      </form>
    </div>
  );
}

export default VarioweltAcademyItemForm;
